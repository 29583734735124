import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SpinnerChase from "components/Shared/SpinnerChase";
import { Link, } from "react-router-dom"
import { Card, CardBody, Row, Col, Alert } from "reactstrap"
import { getInitialsFromName, getSharedTableOptions } from "helpers/utilHelper"
import { getSharedPaginationOptions } from "helpers/utilHelper";
import { route, routes } from "helpers/routeHelper";
import { doMessageDtCleanup, getMessageDt } from "store/actions";
import { formats, formatTimestamp } from "helpers/dateHelper";
import { useSocketOn, useSubscribeToOrderMessages } from "hooks/socket";
import socketEvent from "constants/socketEvent";
import Message from "model/message";
import UserAvatar from "components/Shared/UserAvatar";

const channelMap = Message.getChannelMap();

const DataTable = () => {

  const dispatch = useDispatch();

  /********** STATE **********/

  const { messages: rows, messagesError: rowsError, listParams, isLoadInProgress } = useSelector(state => state.Message.Dt);

  // datatable PaginationProvider options
  const [paginationOptions, setPaginationOptions] = useState({
    ...getSharedPaginationOptions(),
    page: listParams.page,
    sizePerPage: listParams.pageSize,
    defaultSorted: [{
      dataField: listParams.sortBy,
      order: listParams.sortDir,
    }],
  });

  /********** OTHER **********/

  // reloads the table with the same parameters
  const reloadTable = useCallback(() => dispatch(getMessageDt(listParams)), [listParams]);

  /********** SOCKET **********/

  // start receiving messages updates
  useSubscribeToOrderMessages();

  const onMessageReceived = useCallback(reloadTable, [reloadTable]);

  // listen for changes on messages
  useSocketOn(socketEvent.messageReceived, onMessageReceived);

  /********** EFFECTS **********/

  // runs once on component mount
  useEffect(() => {
    // we do not get the list data here
    // instead we listen for changes on 'filters' state var and do it there (see below)
    // this is to avoid fetching the data twice (both on component mount and on filters changed)
    return () => {
      // state cleanup on component unmount
      dispatch(doMessageDtCleanup());
    }
  }, []);

  // runs on component mount
  useEffect(() => {
    // refresh the messages list
    dispatch(getMessageDt({
      ...listParams,
      // otherwise the current page number might be higher than the total number of pages after the filtering
      page: 1,
    }));
    // update the pagination with the new page number
    setPaginationOptions(options => ({
      ...options,
      page: 1,
    }));
  }, []);

  return <React.Fragment>
    <Card>
      <CardBody className="pt-3">
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField='id'
              columns={columns}
              data={rows}>
              {toolkitProps => (
                <React.Fragment>
                  <Row>
                    <Col>
                      <div className="table-responsive">
                        <BootstrapTable
                          {...getSharedTableOptions()}
                          noDataIndication={!rowsError && "No messages found"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                      {isLoadInProgress && <SpinnerChase className="sm dtable" />}
                      {!!rowsError && <Alert color="danger" className="fade show text-center">
                        <i className="mdi mdi-alert-circle-outline me-2"></i>Unable to load messages
                      </Alert>}
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  </React.Fragment>
}

const columns = [{
  dataField: 'orderId',
  text: 'Order ID',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_order, row.orderId)}>{cellContent}</Link>,
}, {
  dataField: 'customerName',
  text: 'Customer name',
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <Link to={route(routes.view_order, row.orderId)}>{cellContent}</Link>,
}, {
  dataField: 'senderName',
  text: 'Message',
  style: {
    maxWidth: 0,
    width: '60%',
  },
  // eslint-disable-next-line react/display-name
  formatter: (cellContent, row) => <>
    <h5 className="text-truncate font-size-14">
      {cellContent}
    </h5>
    <p className="text-muted mb-0 message-ellipsis">
      {row.content}
    </p>
  </>,
}, {
  dataField: 'createdTs',
  text: 'Date',
  // eslint-disable-next-line react/display-name
  formatter: cellContent => formatTimestamp(cellContent, formats.DATETIME),
}, {
  dataField: 'channel',
  text: 'Channel',
  // eslint-disable-next-line react/display-name
  formatter: cellContent => <UserAvatar id={+cellContent} initials={getInitialsFromName(channelMap[cellContent])} size="sm" />
}, {
  dataField: 'dummy1',
  text: '',
  // eslint-disable-next-line react/display-name
  formatter: (_cellContent, row) => <Link to={route(routes.view_order_messages, [row.orderId, row.channel])}>
    <span className={`badge badge-lg rounded-pill bg-primary`}>View Message</span>
  </Link>,
},];

export default DataTable;
